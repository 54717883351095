<template>
    <Modal
        header-class="modal-form-header"
        :has-footer="loaded"
        slot-class=""
        :footer-slot-class="loaded ? 'form-modal-footer p-3' : ''"
        class="position-relative"
        @close="unBlock"
    >
        <template #title>
            <div>
                {{ title }}

                <PlatonFormAdminModalActions v-if="loaded" :form="$refs.form.thisForm" />

                <i class="modal-form-block-title" v-if="isFormBlocked">
                    {{ $l("platon.blocked", "Блокланган") }}
                </i>
            </div>
        </template>

        <template #footer>
            <FormActionButtonBar
                v-if="loaded"
                :buttons="loaded.formElementContainers.formActionButtons"
                position="bottom"
                :form="loaded"
                class="flex-grow-1"
            />
        </template>

        <PlatonForm
            @loaded="(form) => (loaded = form)"
            @formTitle="(t) => (title = t)"
            @close="$emit('close')"
            :route="route"
            ref="form"
        />
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal.vue"
import PlatonFormAdminFooter from "@Platon/components/form/PlatonFormAdminFooter.vue"
import PlatonFormAdminModalActions from "@Platon/components/form/PlatonFormAdminModalActions.vue"
import ModalCloseOnEscMixin from "@Platon/mixins/ModalCloseOnEscMixin"
import FormActionButtonBar from "@Platon/components/form/FormActionButtonBar.vue"

/**
 * @property {PlatonForm} loaded
 */
export default {
    mixins: [ModalCloseOnEscMixin],

    props: {
        route: {}
    },

    data() {
        return {
            title: "Форма",
            loaded: null
        }
    },

    computed: {
        isFormBlocked() {
            return (this.loaded && this.loaded.blockStatus.blocked) || false
        }
    },

    name: "PlatonModalForm",
    components: { FormActionButtonBar, PlatonFormAdminModalActions, PlatonFormAdminFooter, Modal },

    methods: {
        onFooter(footer) {},
        unBlock() {
            this.loaded && this.loaded.unBlock("blocker")
        }
    }
}
</script>
